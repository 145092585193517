export const AppState = {
	INIT: 'INIT',
	VIEW: 'VIEW',
	PANEL_EDIT: 'PANEL_EDIT',
	TARGET_SELECTION: 'TARGET_SELECTION',
	REDIRECT_MODAL_OPENED: 'REDIRECT_MODAL_OPENED',
	TMCE_MODAL_OPENED: 'TMCE_MODAL_OPENED',
	REDIRECTING: 'REDIRECTING',
	PROMO_MODAL_OPENED: 'PROMO_MODAL_OPENED',
	CANCEL: 'CANCEL',
	STEP_CHANGING: 'STEP_CHANGING',
	PAUSE: 'PAUSE',
	PAUSE_TARGET_SEL_1_CLICK: 'PAUSE_TARGET_SEL_1_CLICK',
};

export const ProcessState = {
	IDLE: 'IDLE',
	PROCESSING: 'PROCESSING',
	PROCESSED: 'PROCESSED',
	ERROR: 'ERROR',
};
/*
export const TargetType = {
	STEP: '1',
	TRIGGER: '2',
	MOBILE_MENU: '3',
	URL_VAR_EXAMPLES: '4',
};*/

export const TargetType = {
	STEP: 'step',
	TRIGGER: 'trigger',
	MOBILE_MENU: 'mobileMenu',
	URL_VAR_EXAMPLES: 'urlVarExamples',
};

export const Origin = {
	FRONTEND: 'frontend',
	ADMIN: 'admin',
	SETTINGS: 'settings',
};

export const Position = {
	TOP: 'TOP',
	BOTTOM: 'BOTTOM',
	LEFT: 'LEFT',
	RIGHT: 'RIGHT',
};


