
export default class DpitBB {


	static fromLeftTopWidthHeight(left, top, width, height) {
		return DpitBB.supplyRightBottom({ left, top, width, height });
	}

	static fromLeftTopBottomRight(left, top, bottom, right) {
		return DpitBB.supplyWidthHeight({ left, top, bottom, right });
	}

	static supplyRightBottom(BB) {
		BB.bottom = BB.top + BB.height - 1;
		BB.right = BB.left + BB.width - 1;
		return BB;
	}

	static supplyWidthHeight(BB) {
		BB.height = BB.bottom - BB.top + 1;
		BB.width = BB.right - BB.left + 1;
		return BB;
	}

	static empty = (rightBottom = true, widthHeight = true) => {
		let res = {
			top: 0,
			left: 0,
		};
		if (widthHeight) {
			res.width = 0;
			res.height = 0;
		}
		if (rightBottom) {
			res.right = 0;
			res.bottom = 0;
		}

		return res;
	};

	static area = (BB) => {
		return BB && BB.width > 0 && BB.height > 0 ? BB.width * BB.height : 0;
	};

	static extendByMarginBB = (originalBB, marginBB) => {
		return DpitBB.extend(originalBB, marginBB.top, marginBB.right, marginBB.bottom, marginBB.left);
	};

	static extend = (originalBB, ...paddingArgsPx) => {

		if (originalBB && paddingArgsPx && paddingArgsPx.length > 0) {
			let extensionPaddings = DpitBB.empty(true, false);
			switch (paddingArgsPx.length) {
				case 1:
					extensionPaddings.top = extensionPaddings.bottom = extensionPaddings.left = extensionPaddings.right = paddingArgsPx[0];
					break;
				case 2:
					extensionPaddings.top = extensionPaddings.bottom = paddingArgsPx[0];
					extensionPaddings.left = extensionPaddings.right = paddingArgsPx[1];
					break;
				case 3:
					extensionPaddings.top = paddingArgsPx[0];
					extensionPaddings.right = paddingArgsPx[1];
					extensionPaddings.bottom = paddingArgsPx[2];
					extensionPaddings.left = paddingArgsPx[1];
					break;
				case 4:
					extensionPaddings.top = paddingArgsPx[0];
					extensionPaddings.right = paddingArgsPx[1];
					extensionPaddings.bottom = paddingArgsPx[2];
					extensionPaddings.left = paddingArgsPx[3];
					break;
			}

			const resBB = { ...originalBB };
			resBB.top -= extensionPaddings.top;
			resBB.left -= extensionPaddings.left;
			resBB.bottom += extensionPaddings.bottom;
			resBB.right += extensionPaddings.right;
			return DpitBB.supplyWidthHeight(resBB);
		}
		return null;
	};

	static toElement = (element, BB, omitHeight = false, omitWidth = false) => {
		if (element) {
			Object.assign(element.style, {
				top: BB.top + 'px',
				left: BB.left + 'px',
			});
			if (!omitWidth) {
				element.style.width = BB.width + 'px';
			}
			if (!omitHeight) {
				element.style.height = BB.height + 'px';
			}
		}
	};

	static fromElement = (element, relativeToViewPort = false, defVal = null, scrollTop = null, scrollLeft = null) => {
		if (element) {
			const jElement = $(element);
			const offset = jElement.offset();
			const BCR = element.getBoundingClientRect();


			return DpitBB.absoluteToViewPortRelative(
				DpitBB.fromLeftTopWidthHeight(
					offset.left,
					offset.top,
					BCR.width,
					BCR.height
				),
				relativeToViewPort,
				scrollTop,
				scrollLeft
			);

		}
		return defVal;
	};

	static relativeToAbsolute(BB, enabled = true, topOfRelativeParent = null, leftOfRelativeParent = null) {
		const boundingBoxNew = { ...BB };
		boundingBoxNew.top += enabled ? topOfRelativeParent : 0;
		boundingBoxNew.left += enabled ? leftOfRelativeParent : 0;
		return DpitBB.supplyRightBottom(boundingBoxNew);
	}


	static viewPortRelativeToAbsolute(BB, enabled = true, scrollTop = null, scrollLeft = null) {
		return DpitBB.relativeToAbsolute(
			BB,
			enabled,
			scrollTop ?? (enabled ? $(window).scrollTop() : 0),
			scrollLeft ?? (enabled ? $(window).scrollLeft() : 0)
		);
	}

	static absoluteToRelative(BB, enabled = true, topOfRelativeParent = null, leftOfRelativeParent = null) {
		const boundingBoxNew = { ...BB };
		boundingBoxNew.top -= enabled ? topOfRelativeParent : 0;
		boundingBoxNew.left -= enabled ? leftOfRelativeParent : 0;
		return DpitBB.supplyRightBottom(boundingBoxNew);
	}

	static absoluteToViewPortRelative(BB, enabled = true, scrollTop = null, scrollLeft = null) {
		return DpitBB.absoluteToRelative(
			BB,
			enabled,
			scrollTop ?? (enabled ? $(window).scrollTop() : 0),
			scrollLeft ?? (enabled ? $(window).scrollLeft() : 0)
		);
	}

	static merge(...BBs) {
		if (BBs.length) {
			const notNulBBs = BBs.filter(BB => BB);
			const tops = notNulBBs.map(BB => BB.top);
			const lefts = notNulBBs.map(BB => BB.left);
			const bottoms = notNulBBs.map(BB => BB.bottom);
			const rights = notNulBBs.map(BB => BB.right);

			if (tops && tops.length && lefts && lefts.length && bottoms && bottoms.length && rights && rights.length) {
				return DpitBB.supplyWidthHeight({
					top: Math.min(...tops),
					left: Math.min(...lefts),
					right: Math.max(...rights),
					bottom: Math.max(...bottoms)
				});
			}
		}
		return null;
	}

	static intersect(...BBs) {
		if (BBs.length) {
			const notNulBBs = BBs.filter(BB => BB);
			const tops = notNulBBs.map(BB => BB.top);
			const lefts = notNulBBs.map(BB => BB.left);
			const bottoms = notNulBBs.map(BB => BB.bottom);
			const rights = notNulBBs.map(BB => BB.right);

			if (tops && tops.length && lefts && lefts.length && bottoms && bottoms.length && rights && rights.length) {
				const BB = DpitBB.supplyWidthHeight({
					top: Math.max(...tops),
					left: Math.max(...lefts),
					right: Math.min(...rights),
					bottom: Math.min(...bottoms)
				});
				return DpitBB.area(BB) > 0 ? BB : null;
			}
		}
		return null;
	}

	mergeFromElements(...elements) {
		return DpitBB.merge(...elements.map(el => DpitBB.fromElement(el)));
	}



	static diff(minuendBB, subtrahendBB) {
		return {
			top: minuendBB.top - subtrahendBB.top,
			left: minuendBB.left - subtrahendBB.left,
			bottom: minuendBB.bottom - subtrahendBB.bottom,
			right: minuendBB.right - subtrahendBB.right,
			width: minuendBB.width - subtrahendBB.width,
			height: minuendBB.height - subtrahendBB.height,
		};
	}

	static isEqual(firstBB, secondBB) {
		return firstBB.top === secondBB.top
			&& firstBB.left === secondBB.left
			&& firstBB.bottom === secondBB.bottom
			&& firstBB.right === secondBB.right;
	}

	static diffAbs(minuendBB, subtrahendBB) {
		const diffBB = DpitBB.diff(minuendBB, subtrahendBB);
		return {
			top: Math.abs(diffBB.top),
			left: Math.abs(diffBB.left),
			bottom: Math.abs(diffBB.bottom),
			right: Math.abs(diffBB.right),
			width: Math.abs(diffBB.width),
			height: Math.abs(diffBB.height)
		};
	}

	static isInside(testedBB, containerBB, thresholdRatio = 1) {
		const intersectionRatio = DpitBB.getIntersectionRatio(testedBB, containerBB);
		return intersectionRatio >= thresholdRatio;
	}

	static getIntersectionRatio(testedBB, containerBB) {
		const intersection = DpitBB.intersect(testedBB, containerBB);
		return DpitBB.area(intersection) / DpitBB.area(testedBB);
	}
}