import scrollIntoViewCore from 'scroll-into-view';
import replaceString from 'replace-string';
import urlJoin from 'url-join';
import { RESPONSIVE_MODE } from '../../../../public/assets/js/modules/core/constants';
import { TargetType } from '../../../../public/assets/js/apps/TourStepEditor/store/appMode';



export const init = (dpDebugEn) => {
	if (dpDebugEn) console.log('[dpit] INIT dpitUtilsInc');
};

export const strCapitalize = (inputString) => {
	return inputString.charAt(0).toUpperCase() + inputString.slice(1);
};

export const isVariableUrl = (url) => {
	const start = url.indexOf('{');
	const stop = url.indexOf('}');
	return start >= 0 && stop > start;
};

export const fillUrlVariables = (urlVars, url) => {
	if (urlVars && url) {
		Object.keys(urlVars).forEach((varId) => {
			url = replaceString(url, `{${varId}}`, urlVars[varId]);
		});
	}
	return url;
};

export const simpleObjectToStr = (obj, separator = '|', valueNameSeparator = '=') => {
	let res = '';
	if (obj) {
		Object.keys(obj).forEach((key, idx) => {
			if (idx != 0) res += separator;
			res += `${key}${valueNameSeparator}${obj[key]}`;
		});
	}
	return res;
};

export const strToSimpleObject = (str, separator = '|', valueNameSeparator = '=') => {
	const res = {};
	if (str) {
		const items = str.split(separator);
		if (items) {
			items.forEach((item) => {
				const parts = item.split(valueNameSeparator);
				if (parts && parts.length >= 2) {
					res[parts[0]] = parts[1];
				}
			});
		}
	}
	return res;
};

export const getInfoIconHtml = (className, text, title = '', returnJQueryItem = true) => {
	if (title) text = `<strong class="info-tip-title">${title}</strong>${text}`;
	if (returnJQueryItem) return $(`<div class="${className}"></div>`).attr('data-info-text', text);
	else return `<div class='${className}' data-info-text='${text}'></div>`;
};

export const strLastPart = (text, delimiter = '.', def = '') => {
	const dataParts = text.split(delimiter);
	if (dataParts && dataParts.length > 0) {
		return dataParts[dataParts.length - 1];
	}
	return def;
};

export const strFirstPart = (text, delimiter = '.', def = '') => {
	const dataParts = text.split(delimiter);
	if (dataParts && dataParts.length > 0) {
		return dataParts[0];
	}
	return def;
};

export const rightTrimByString = (url, delimiter = '.') => {

	const pos = url.indexOf(delimiter);
	if (pos >= 0) {
		return url.substring(0, pos);
	}
	return url;
};

export const fixQMarkWithNoSlash = (url) => {
	const qMarkIdx = url.indexOf('?');
	if (qMarkIdx >= 0) {
		if (qMarkIdx === 0 || url[qMarkIdx - 1] != '/') {
			url = `${url.substring(0, qMarkIdx)}/${url.substring(qMarkIdx)}`;
		}
	}
	return url;
};

export const forceQMarkWithoutSlash = (url) => {
	const qMarkIdx = url.indexOf('?');
	if (qMarkIdx > 0 && url[qMarkIdx - 1] == '/') {
		url = url.substring(0, qMarkIdx - 1) + url.substring(qMarkIdx);
	}
	return url;
};

export const sanitizePossibleRelativeUrl = (url, mainSiteUrl, forceSlashBeforeQMark = false) => {
	if (url.startsWith('/')) {
		url = urlJoin(mainSiteUrl, url);
		if (forceSlashBeforeQMark) {
			url = fixQMarkWithNoSlash(url);
		}
	}
	return url;
};

export const absToRelativeUrl = (url, mainSiteUrl) => {
	if (mainSiteUrl && url.startsWith(mainSiteUrl)) {
		url = url.substring(mainSiteUrl.length);
		if (!url.startsWith('/')) url = `/${url}`;
	}
	return url;
};

export const removeURLParameter = (url, parameter) => {
	// prefer to use l.search if you have a location/link object
	const urlParts = url.split('?');
	if (urlParts.length >= 2) {
		const prefix = `${encodeURIComponent(parameter)}=`;
		const pars = urlParts[1].split(/[&;]/g);

		// reverse iteration as may be destructive
		for (let i = pars.length; i-- > 0;) {
			// idiom for string.startsWith
			if (pars[i].lastIndexOf(prefix, 0) !== -1) {
				pars.splice(i, 1);
			}
		}

		return urlParts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
	}
	return url;
};

export const removeURLParameters = (url, parameters) => {
	let paramKeys = parameters;
	if (!Array.isArray(parameters)) paramKeys = Object.keys(parameters);
	paramKeys.forEach((parameter) => {
		url = removeURLParameter(url, getDpUrlParamName(parameter, parameters));
	});
	return url;
};

export const unifyUrl = (url, removeQuery = true, removeHash = true, removeTrailingSlashes = true, prependSlash = false) => {
	if (url) {
		url = strLastPart(url, '://', url);
		if (url && url.startsWith('www.')) url = url.substring(4);
		if (url && removeHash) url = strFirstPart(url, '#', url);
		if (url && removeQuery) {
			url = strFirstPart(url, '?', url);
		} else {
			url = fixQMarkWithNoSlash(url);
		}
		if (url) {
			let regExp = /\/+$/;
			if (!removeTrailingSlashes) {
				regExp = /^\/+/g;
			}
			url = url.replace(regExp, '');
		}
		if (url && prependSlash) {
			url = `/${url}`;
		}
	}
	if (!url) {
		url = '/';
	}
	else {
		if (url.endsWith('/index.php')) {
			url = rightTrimByString(url, '/index.php');
		} else if (!removeQuery) {
			url = replaceString(url, '/index.php/', '/');
		}
	}
	return url;
};

export const isUrlAtOurSite = (urlToTest, siteUrl) => {
	return urlToTest.startsWith(siteUrl);
};
/*
export const isUrlAtOurSite = (urlToTest, siteUrl) => {
	let urlToTestAbs = sanitizePossibleRelativeUrl(urlToTest, siteUrl);
	return urlToTestAbs.startsWith(siteUrl);

};*/


export const getDpUrlParamName = (id, queryParamsDefinitions) => {
	let res = id;
	if (queryParamsDefinitions) res = queryParamsDefinitions[id];
	return res;
};

export const getDpUrlParam = (id, queryParamsDefinitions, currentUrl = null, defVal = null) => {
	let res = defVal;
	const key = getDpUrlParamName(id, queryParamsDefinitions);
	if (key) {
		if (!currentUrl) currentUrl = new URL(window.location);
		res = currentUrl.searchParams.get(key);
	}
	return res;
};

export const setDpUrlParam = (id, queryParamsDefinitions, val, postfixAmpersand = true) => {
	let res = '';
	const key = getDpUrlParamName(id, queryParamsDefinitions);
	if (key) {
		res = key + '=' + val;
		if (postfixAmpersand) res += '&';
	}
	return res;
};

export const buildDpQueryString = (params, queryParamsDefinitions, prefix = '?', unmappedParams = null) => {
	let res = prefix;
	if (unmappedParams) {
		const idsUm = Object.keys(unmappedParams);
		idsUm.forEach((id) => {
			if (unmappedParams[id] !== null && unmappedParams[id] !== undefined && unmappedParams[id] !== '')
				res += id + '=' + unmappedParams[id] + '&';
		});
	}
	if (params) {
		const ids = Object.keys(params);
		ids.forEach((id) => {
			if (params[id] !== null && params[id] !== undefined && params[id] !== '')
				res += setDpUrlParam(id, queryParamsDefinitions, params[id]);
		});
	}

	if (res && res[res.length - 1] == '&') res = res.substr(0, res.length - 1);
	if (res == prefix) res = '';
	return res;
};

export const decodeStepParamAdvancedVal = (value, paramName, stepDefs, mainValOnlyForSimpleVal = true, onlyValForExtProps = false) => {
	const stepDef = stepDefs?.[paramName];
	const extCfg = stepDef?.ext;
	let res = {
		val: value ?? stepDef?.defMain,
		ext: {}
	};
	if (value?.startsWith('base64__') || extCfg) {
		let dataObj = null;
		if (value?.startsWith('base64__')) {
			const dataObjStr = atob(value.substr(8));
			dataObj = dataObjStr ? JSON.parse(dataObjStr) : null;
			res.val = dataObj?.val ?? stepDef?.defMain;
		}
		if (extCfg) {
			Object.keys(extCfg).forEach(id => {
				if (onlyValForExtProps) {
					res.ext[id] = dataObj?.ext?.[id] ?? extCfg[id]?.def;
				}
				else {
					res.ext[id] = {
						...extCfg[id],
						val: dataObj?.ext?.[id] ?? extCfg[id]?.def
					};
				}
			});
		}

	} else if (mainValOnlyForSimpleVal) {
		res = value;
	}
	return res;
};

export const encodeStepParamAdvancedVal = (value, paramName, stepDefs) => {
	const extCfg = stepDefs?.[paramName]?.ext;
	return extCfg ? 'base64__' + btoa(JSON.stringify(value)) : value;
};

export const initStepParams = (stepDefinitions) => {
	const res = {};
	Object.keys(stepDefinitions).forEach(paramName => {
		let mainVal = '';
		if (stepDefinitions[paramName]) {
			mainVal = stepDefinitions[paramName].def || '';
			if (stepDefinitions[paramName].ext) {
				let extVals = {};
				Object.keys(stepDefinitions[paramName].ext).forEach(extName => {
					extVals[extName] = stepDefinitions[paramName].ext[extName].def;
				});
				mainVal = {
					val: mainVal,
					ext: extVals
				};
			}
		}
		res[paramName] = mainVal;
	});
	return res;
};

export const getNthStr = (idx, supEnabled = true) => {
	if (idx !== false && !isNaN(idx)) {
		let supStart = '';
		let supEnd = '';
		if (supEnabled) {
			supStart = '<span class="dpit-sup">';
			supEnd = '</span>';
		}
		let res = '';
		switch (idx) {
			case 1: res = `1${supStart}st${supEnd}`; break;
			case 2: res = `2${supStart}nd${supEnd}`; break;
			case 3: res = `3${supStart}rd${supEnd}`; break;
			default: res = `${idx}${supStart}th${supEnd}`; break;
		}
		return res;
	}
	return '';
};


export const getCssVar = (variableName, def = null, element = document.documentElement) => {
	let res = def;
	if (element && variableName) {
		const tmp = getComputedStyle(element).getPropertyValue(variableName);
		if (tmp !== null && tmp !== undefined && tmp !== '') {
			res = tmp;
		}
	}
	return res;
};

export const updateCssVar = (name, value, element = document.documentElement) => {
	element.style.setProperty(name, value);
};

export const textStylesIsHOrPOption = ($key) => {
	return $key.length >= 3 && ($key[0] === 'p' && $key[1] === '_' || $key[0] === 'h' && $key[2] === '_');
};

export const textStylesIsFontFamilyVal = ($key) => {
	return ['p_font', 'h_font', 'btn_font'].includes($key);
};

export const setMobileSizeCoef = (textStylesCfg, winBB = null, mobileBreakPoint = -1, element = document.documentElement) => {
	if (textStylesCfg) {
		const mobSizeCoef = (mobileBreakPoint > 0 && winBB && winBB.width <= mobileBreakPoint) ? textStylesCfg.mobile_size_coef / 100 : 1;
		updateCssVar('--font-size-coef', mobSizeCoef * textStylesCfg.all_in_1_size_coef / 100, element);
	}
};

export const round1dec = (number) => {
	return Math.round(number * 10) / 10;
};

export const floor1dec = (number) => {
	return Math.floor(number * 10) / 10;
};

export const ceil1dec = (number) => {
	return Math.ceil(number * 10) / 10;
};

export const round3dec = (number) => {
	return Math.round(number * 1000) / 1000;
};

export const round6dec = (number) => {
	return Math.round(number * 1000000) / 1000000;
};

export const setTextStyles = (textStylesCfg, winBB = null, mobileBreakPoint = -1, element = document.documentElement) => {

	if (textStylesCfg && element) {
		setMobileSizeCoef(textStylesCfg, winBB, mobileBreakPoint, element);
		const text_styles = { ...textStylesCfg };
		const font_size_unit = text_styles.font_size_unit ?? 'em';
		const cssVariables = [];

		Object.keys(text_styles).forEach(propName => {
			if (textStylesIsFontFamilyVal(propName)) {
				cssVariables[`--${propName}`] = (text_styles[propName] || text_styles[propName] == '0') ? text_styles[propName] : 'inherit';
			}
		});

		if (text_styles.p_font_size || text_styles.p_font_size === '0') {
			cssVariables['--p_font_size'] = 'calc(' + text_styles.p_font_size + font_size_unit + '*var(--font-size-coef))';
			if (text_styles.p_mb || text_styles.p_mb === '0') {
				cssVariables['--p_mb'] = 'calc(' + round3dec(text_styles.p_mb / 100) + '*' + cssVariables['--p_font_size'] + ')';
			}
		}

		if (text_styles.h2_font_size || text_styles.h2_font_size == 0 && text_styles.h5_font_size || text_styles.h5_font_size == 0) {
			const unit = (text_styles.h2_font_size - text_styles.h5_font_size) / 4;
			for (let i = 1; i <= 6; i++) {
				const unitCnt = i - 2;
				cssVariables[`--h${i}_font_size`] = 'calc(' + round3dec(text_styles.h2_font_size - unitCnt * unit) + font_size_unit + '*var(--font-size-coef))';
			}
		}

		if (text_styles.h2_mb || text_styles.h2_mb == 0 && text_styles.h5_mb || text_styles.h5_mb == 0) {
			const unit = (text_styles.h2_mb - text_styles.h5_mb) / 4;
			for (let i = 1; i <= 6; i++) {
				const unitCnt = i - 2;
				const percentVal = round3dec(text_styles.h2_mb - unitCnt * unit);
				cssVariables[`--h${i}_mb`] = 'calc(' + round3dec(percentVal / 100) + '*' + cssVariables[`--h${i}_font_size`] + ')';
				if (i === 2) {
					cssVariables['--dpit-unit'] = 'calc(' + round3dec(percentVal * 1.3 /*line height*/ / 1000 /* smaller unit */) + '*' + cssVariables[`--h${i}_font_size`] + ')';
				}
			}
		}

		Object.keys(cssVariables).forEach(propName => {
			updateCssVar(propName, cssVariables[propName], element);
		});
	}
};

export const asyncWait = (timeoutMs = 10) => {
	return new Promise(resolve => {
		setTimeout(() => resolve(true), timeoutMs);
	});
};


export const loadBuilder = (mainCfg, instanceCreatorFce) => {
	if (mainCfg.dpDebugEn) console.log('[dpit] INIT Builder config:', mainCfg);
	const currentUrl = new URL(window.location);
	const queryParamsDefs = mainCfg.queryParamsDefs;
	const tourId = getDpUrlParam('dp_qp_tour_id', queryParamsDefs, currentUrl);
	const createNew = getDpUrlParam('dp_qpb_create_new', queryParamsDefs, currentUrl);
	const builderMode = getDpUrlParam('dp_qpb_builder_mode', queryParamsDefs, currentUrl);
	if ((mainCfg.tours && mainCfg.tours.length) || builderMode === TargetType.MOBILE_MENU) {
		const mainCfgProcessed = { ...mainCfg };
		delete mainCfgProcessed.tours;
		mainCfgProcessed.builderOrigin = getDpUrlParam('dp_qpb_builder_origin', queryParamsDefs, currentUrl);
		mainCfgProcessed.builderMode = builderMode;
		mainCfgProcessed.originHashId = getDpUrlParam('dp_qpb_origin_el_id', queryParamsDefs, currentUrl);
		mainCfgProcessed.triggerId = getDpUrlParam('dp_qp_trigger_id', queryParamsDefs, currentUrl) || 0;
		mainCfgProcessed.initState = getDpUrlParam('dp_qpb_init_state', queryParamsDefs, currentUrl);
		mainCfgProcessed.inNewWindow = getDpUrlParam('dp_qpb_in_new_window', queryParamsDefs, currentUrl) === '1';
		mainCfgProcessed.mobileAltStepMode = getDpUrlParam('dp_qpb_mobile_alt_step_mode', queryParamsDefs, currentUrl);
		const delay = mainCfgProcessed.mobileAltStepMode === RESPONSIVE_MODE.mobileMenu ? 500 : 0;
		setTimeout(() => {
			if (builderMode === TargetType.MOBILE_MENU) {
				instanceCreatorFce(mainCfgProcessed, null);
			} else {

				mainCfg.tours.forEach((tourCfg) => {
					if ((createNew && tourCfg.createNew) || tourCfg.tourId == tourId) {
						if (tourCfg && tourCfg.properties) tourCfg.properties.disableStartAnimation = builderMode !== TargetType.STEP;
						instanceCreatorFce(mainCfgProcessed, tourCfg);
					}
				});
			}
		}, delay);
	}
};

export const getAdminBarHeight = () => $('#wpadminbar').outerHeight() || 0;


/**
 * Scroll element into view
 * @param {Element} element element to scroll into view
 * @param {options} object scrollIntoView options see https://github.com/KoryNunn/scroll-into-view
 * @returns {Promise}
 */
export const scrollIntoView = (element, options = { align: { top: 0.5, left: 0.5 }, cancellable: true }) => {
	return new Promise((resolve) => {
		scrollIntoViewCore(element, options, resolve);
	});
};


/**
 * Insert animation and highlight class to the element
 * @param {Element} element
 * @param {string} animationClass
 * @param {options} options scrollIntoView options see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
 * @param {boolean} addHighlightClass
 * @param {string} removeHighlightClickChildSelector
 * @param {string} highlightClass
 */
export const scrollToElementAndAnimate = async (
	element,
	animationClass = 'dpit-admin-pulse-anim',
	animTimeMs = 1000,
	options = { align: { top: 0.5, left: 0.5 } },
	addHighlightClass = false,
	removeHighlightClickChildSelector = '',
	highlightClass = 'dpit-admin-err-highlight'
) => {
	if (element) {
		await scrollIntoView(element, options);
		return await animateElement(element, animationClass, animTimeMs, addHighlightClass, removeHighlightClickChildSelector, highlightClass);
	}
};

/**
 * Insert animation and highlight class to the element
 * @param {Element} element
 * @param {string} animationClass
 * @param {boolean} addHighlightClass
 * @param {string} removeHighlightClickChildSelector
 * @param {string} highlightClass
 */
export const animateElement = async (element, animationClass = 'dpit-admin-pulse-anim', animTime = 1000, addHighlightClass = false, removeHighlightClickChildSelector = '', highlightClass = 'dpit-admin-err-highlight') => {
	function removeHighlightClass() {
		element.classList.remove(highlightClass);
		const $removeHighlightClickTargetEl = $(removeHighlightClickChildSelector);
		if ($removeHighlightClickTargetEl) {
			$removeHighlightClickTargetEl.off('click', removeHighlightClass);
		}
	}
	if (addHighlightClass && highlightClass && !element.classList.contains(highlightClass)) {
		element.classList.add(highlightClass);

		if (removeHighlightClickChildSelector) {
			const $removeHighlightClickTargetEl = $(removeHighlightClickChildSelector);
			if ($removeHighlightClickTargetEl) {
				$removeHighlightClickTargetEl.on('click', removeHighlightClass);
			}
		}
	}
	if (animationClass) {
		element.classList.add(animationClass);
		return new Promise(resolve => {
			setTimeout(() => {
				element.classList.remove(animationClass);
				resolve();
			}, animTime);
		});
	} else {
		return Promise.resolve();
	}
};

/**
  * @param {HTMLElement|Node} element
 * @param {String} containerClassName
 * @param {String} containerId
 * @param {HTMLElement|Node} appendFooterEl
 * @return {HTMLElement|Node} returns container element
 */
export const wrapElementByContainer = (element, containerClassName = '', containerId = '', appendFooterEl = null) => {
	let containerEl = document.createElement('div');
	containerEl.className = containerClassName;
	containerEl.id = containerId;
	containerEl.appendChild(element);
	if (appendFooterEl) {
		containerEl.appendChild(appendFooterEl);
	}
	return containerEl;
};

export const deleteCookie = (name) => {
	document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const setCookie = (name, value, expiresSeconds) => {
	const d = new Date();
	d.setTime(d.getTime() + (expiresSeconds * 1000));
	let expires = 'expires=' + d.toUTCString();
	document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

export const getCookie = (name) => {
	const cookies = Object.fromEntries(document.cookie.split('; ').map(v => v.split(/=(.*)/s).map(decodeURIComponent)));
	return cookies ? cookies[name] : '';
};

export const getCookieAndDelete = function (name) {
	const value = getCookie(name);
	deleteCookie(name);
	return value;
};


/**
 * @param {Element} element
 */
export const isElementVisible = (element) => {
	if (!element) return false;
	const style = getComputedStyle(element);
	return style.getPropertyValue('display') !== 'none'
		&& style.getPropertyValue('visibility') !== 'hidden'
		&& style.getPropertyValue('opacity') !== '0';
};

/**
 * @param {Element} element
 */
export const isElementVisibleParentInc = (element) => {
	if (!element) return false;
	let parentElm = element;
	while (parentElm != null) {
		if (!parentElm.tagName || parentElm.tagName.toLowerCase() === 'body') break;
		if (!isElementVisible(parentElm)) {
			return false;
		}
		parentElm = parentElm.parentNode;
	}
	return true;
};


/**
 * Remove an entry from a string array if it's there, does nothing if it isn't there.
 *
 * @param {Array} stringArray
 * @param {String} stringToRemove
 */
export const removeEntry = (stringArray, stringToRemove) => {
	if (stringArray.indexOf(stringToRemove) > -1) {
		stringArray.splice(stringArray.indexOf(stringToRemove), 1);
	}
};









