import { getCookieAndDelete } from '../../../../includes/assets/js/modules/dpitUtilsInc';
import DpitBB from './core/DpitBB';

export const $S = (selector, def = null) => {
	try {
		return $(selector);
	} catch (error) {
		return def;
	}
};

export const documentQuerySelectorSafe = (selector) => {
	try {
		return document.querySelector(selector);
	} catch {
		return null;
	}
};

export const isTouchDevice = () => {
	try {
		return (('ontouchstart' in window)
			|| (navigator.maxTouchPoints > 0)
			|| (navigator.msMaxTouchPoints > 0));
	} catch (exception) {
		return false;
	}
};

export const i18n = (text, translations) => {
	if (translations[text]) return translations[text];
	return text;
};


export const getScrollPosition = () => {
	// modern browsers
	if (typeof window.scrollX !== 'undefined' && typeof window.scrollY !== 'undefined') {
		return {
			left: window.scrollX,
			top: window.scrollY
		};
	}
	// obsolete browsers
	if (typeof document.documentElement.scrollLeft !== 'undefined' && typeof document.documentElement.scrollTop !== 'undefined') {
		return {
			left: document.documentElement.scrollLeft,
			top: document.documentElement.scrollTop
		};
	}

	return { left: 0, top: 0 };
};

export const getMobileAddressBarHeight = (winHeight) => {
	// Check if the page is loaded in a mobile browser
	if (/Mobi/.test(navigator.userAgent)) {
		// Calculate the height of the viewport without the address bar
		const screenHeight = winHeight;
		const visualViewportHeight = window.visualViewport ? window.visualViewport.height : screenHeight;
		return Math.min(0, screenHeight - visualViewportHeight);
	}
	return 0;
};

export const getDocBB = () => {
	const body = document.body;
	const html = document.documentElement;

	const height = Math.max(
		body.scrollHeight,
		//body.offsetHeight,
		html.clientHeight,
		html.scrollHeight,
		//html.offsetHeight
	);
	const width = Math.max(
		body.clientWidth,
		//body.offsetWidth,
		html.clientWidth,
		//html.scrollWidth, // causes problem with resize reaction
		//html.offsetWidth
	);

	return DpitBB.fromLeftTopWidthHeight(0, 0, width, height);


	/*const docElBB = DpitBB.fromElement(document.documentElement);
	const bodyBB = DpitBB.fromElement(document.body);
	if (docElBB.top > 0) return DpitBB.fromLeftTopBottomRight(0, 0, Math.max(docElBB.bottom, bodyBB.bottom), Math.max(docElBB.right, bodyBB.right));
	else return docElBB;*/
};

export const getWinBB = () => {
	const width = $(window).innerWidth();
	let height = $(window).innerHeight();


	//const width = document.documentElement.clientWidth || window.innerWidth;
	//const height = document.documentElement.clientHeight || window.innerHeight;


	/*const width = window.innerWidth
		|| document.documentElement.clientWidth
		|| document.body.clientWidth;

	const height = window.innerHeight
		|| document.documentElement.clientHeight
		|| document.body.clientHeight;*/

	return DpitBB.fromLeftTopWidthHeight(0, 0, width, height);
};

export const isBoundingBoxInsideContainer = (bbToTest, containerBB, hasBbToTestFixedPos, thresholdRatio = 1) => {
	return DpitBB.isInside(DpitBB.absoluteToViewPortRelative(bbToTest, !hasBbToTestFixedPos), containerBB, thresholdRatio);
};

export const isElementInsideContainer = (elementToTest, containerBB, hasBbToTestFixedPos, thresholdRatio = 1) => {
	const bbToTest = DpitBB.fromElement(elementToTest, !hasBbToTestFixedPos);
	return bbToTest ? DpitBB.isInside(bbToTest, containerBB, thresholdRatio) : false;
};

export const dpZIndex = (increment = 0) => {
	return `calc(var(--dp-z-index-base) + ${increment})`;
};


export const getTransitionDurationMs = (htmlEl, def = 0) => {
	if (htmlEl) {
		let overlayContainerStyle = window.getComputedStyle(htmlEl);
		if (overlayContainerStyle && overlayContainerStyle.transitionDuration) {
			let num = parseFloat(overlayContainerStyle.transitionDuration);
			if (!isNaN(num)) {
				if (overlayContainerStyle.transitionDuration.endsWith('ms')) return num;
				else if (overlayContainerStyle.transitionDuration.endsWith('s')) return num * 1000;
				else return num;
			}
		}
	}
	return def;
};


export const hasCssPropertyPrefixed = (elementStyle, propertyName, notSetValues = ['none'],
	prefixes = ['-webkit', '-moz-transform', '-ms-transform', '-o-transform']) => {
	return prefixes.some(prefix => {
		const val = elementStyle.getPropertyValue(prefix + '-' + propertyName);
		return (val !== '' && !notSetValues.includes(val));
	});
};

export const hasCssProperty = (elementStyle, propertyName, includeValue = [], notSetValues = ['none']) => {
	const val = elementStyle.getPropertyValue(propertyName);
	if (includeValue.length) return (includeValue.includes(val));
	else return (val !== '' && !notSetValues.includes(val));
};


export const getComputedCssValFloat = (htmlEl, cssParam, def = 0, computedStyle = null) => {
	let res = def;
	if (htmlEl && cssParam) {
		const computedStyleFinal = computedStyle ? computedStyle : getComputedStyle(htmlEl);
		res = parseFloat(computedStyleFinal.getPropertyValue(cssParam));
		if (isNaN(res)) res = def;
	}
	return res;
};

export const getComputedCssValInt = (htmlEl, cssParam, def = 0, computedStyle = null) => {
	let res = def;
	if (htmlEl && cssParam) {
		const computedStyleFinal = computedStyle ? computedStyle : getComputedStyle(htmlEl);
		res = parseInt(computedStyleFinal.getPropertyValue(cssParam));
		if (isNaN(res)) res = def;
	}
	return res;
};

export const getCssValFloat = (htmlEl, cssParam, def = 0) => {
	let res = def;
	if (htmlEl && cssParam) {
		res = parseFloat($(htmlEl).css(cssParam));
		if (isNaN(res)) res = def;
	}
	return res;
};

export const getIntVal = (value, def = null) => {
	let res = def;
	res = parseInt(value);
	if (isNaN(res)) res = def;
	return res;
};


export const getReverseBasePos = (pos) => {
	switch (pos) {
		case 'left': return 'right';
		case 'right': return 'left';
		case 'top': return 'bottom';
		case 'bottom': return 'top';
		default: return pos;
	}
};

export const hasElementScrollBarX = function (element, computedStyle = null) {
	const computedStyleFinal = computedStyle ? computedStyle : getComputedStyle(element);
	const overflowX = computedStyleFinal.getPropertyValue('overflow-x');
	return overflowX === 'scroll' || (element.scrollWidth > element.clientWidth && overflowX === 'auto');
};

export const hasElementScrollBarY = function (element, computedStyle = null) {
	const computedStyleFinal = computedStyle ? computedStyle : getComputedStyle(element);
	const overflowY = computedStyleFinal.getPropertyValue('overflow-y');
	return overflowY === 'scroll' || (element.scrollHeight > element.clientHeight && overflowY === 'auto');
};


export const hasElementScrollBar = function (element, x = false, y = true, computedStyle = null) {
	let hasInX = true;
	const computedStyleFinal = computedStyle ? computedStyle : getComputedStyle(element);
	if (x) {
		hasInX = hasElementScrollBarX(element, computedStyleFinal);
	}
	let hasInY = true;
	if (y) {
		hasInY = hasElementScrollBarY(element, computedStyleFinal);
	}
	return hasInX && hasInY;
};

export const hasElementAnyScrollBar = function (element, computedStyle = null) {
	const computedStyleFinal = computedStyle ? computedStyle : getComputedStyle(element);
	const hasInX = hasElementScrollBarX(element, computedStyleFinal);
	const hasInY = hasElementScrollBarY(element, computedStyleFinal);
	return hasInX || hasInY;
};

export const isElementScrollableX = function (element, computedStyle = null) {
	const computedStyleFinal = computedStyle ? computedStyle : getComputedStyle(element);
	const overflowX = computedStyleFinal.getPropertyValue('overflow-x');
	return element.scrollWidth > element.clientWidth && (overflowX === 'auto' || overflowX === 'scroll');
};

export const isElementScrollableY = function (element, computedStyle = null) {
	const computedStyleFinal = computedStyle ? computedStyle : getComputedStyle(element);
	const overflowY = computedStyleFinal.getPropertyValue('overflow-y');
	return element.scrollHeight > element.clientHeight && (overflowY === 'auto' || overflowY === 'scroll');
};

export const isElementScrollable = function (element, computedStyle = null) {
	const computedStyleFinal = computedStyle ? computedStyle : getComputedStyle(element);
	const hasInX = isElementScrollableX(element, computedStyleFinal);
	const hasInY = isElementScrollableY(element, computedStyleFinal);
	return hasInX || hasInY;
};

export const processDisableOnLoadCookie = function () {
	return getCookieAndDelete('dpit-close-reload') === '1';
};



export const isLinkElementWithNonEmptyHref = function (element, excludeTargetBlank = true) {
	return element.tagName === 'A'
		&& element.hasAttribute('href')
		&& element.getAttribute('href').trim() !== ''
		&& (!excludeTargetBlank
			|| !element.hasAttribute('target')
			|| element.getAttribute('target').trim() !== '_blank'
		);
};

export const getParentsLinkElements = function (element, includeCurrentElement = false, excludeTargetBlank = true) {
	if (!element) return [];
	const res = [];
	let currentElement = includeCurrentElement ? element : element.parentElement;
	while (currentElement) {
		if (isLinkElementWithNonEmptyHref(currentElement, excludeTargetBlank)) {
			res.push(currentElement);
		}
		currentElement = currentElement.parentElement;
	}
	return res;
};

export const getParentsAndChildrenLinkElements = function (element, includeCurrentElement = false, excludeTargetBlank = true) {
	if (!element) return [];
	const descendantLinks = Array.from(element.getElementsByTagName('a'))
		.filter(descendantLinkEl => isLinkElementWithNonEmptyHref(descendantLinkEl, excludeTargetBlank));
	const res = [...descendantLinks, ...getParentsLinkElements(element, includeCurrentElement)];
	return res;
};











