
export const VERSION = '5.1.0';

export const IS_DEBUG = false;

export const VISUALIZE_BB = IS_DEBUG;

export const ANIM_DBG = false;

export const FEATURES = {
	controlsAutoFocusEnable: true,
	goldenRatioPosAlgEn: true,
	followTargetEn: true,
	resizeRefreshEn: true,
	finalizeStepChangeEn: true,
	userInteraction: true
};

export const CLASSES_CORE = {
	stepSpecifier: 'dpit-step',
	themeSpecifier: 'dpit-theme',
	lightAccent: 'dpit-light-accent',
	wrap: 'dpit-wrap',
	highlight: 'dpit-highlight',
	flier: 'dpit-flier',
	tooltipReference: 'dpit-tooltip-ref',
	tooltip: 'dpit-tooltip',
	tooltipRadiusPadding: 'dpit-tooltip-radius-padding',
	tooltipText: 'dpit-tooltip-text',
	arrow: 'dpit-arrow',
	progress: 'dpit-progress',
	progressBar: 'dpit-progress-bar',
	tooltipButtons: 'dpit-tooltip-buttons',
	bullets: 'dpit-bullets',
	rbAnim: 'dpit-anim-rb',
	number: 'dpit-number',
	button: 'dpit-button',
	buttonPrevNextWrap: 'dpit-button-pn-wrap',
	buttonNext: 'dpit-button--next',
	buttonPrev: 'dpit-button--prev',
	buttonSkip: 'dpit-button--skip',
	buttonDone: 'dpit-button--done',
	overlay: 'dpit-overlay',
	overlayContainer: 'dpit-overlay-container',
	disableInteraction: 'dpit-disable-interaction',
	hidden: 'dpit-hidden',
	hiddenKeepEvents: 'dpit-hidden-keep-events',
	hiddenHard: 'dpit-hidden-hard',
	disabled: 'dpit-disabled',
	tourInRun: 'dpit-on',
	pause: 'dpit-pause',
	noTrans: 'dpit-no-trans',
	target: 'dpit-target',
	noEvents: 'dpit-no-events',
	targetParent: 'dpit-target-parent',
	relativePosition: 'dpit-relative-position',
	fixedPosition: 'dpit-fixed-position',
	targetDummy: 'dpit-target-dummy',
	isStickyTheme: 'dpit-is-sticky-theme',
	dpitScrollBar: 'dpit-scrollbar',
	hasScrollBar: 'dpit-has-scrollbar'
};

export const CLASSES_CHILD_POSTFIX = {
	text: '__text',
	icoWrap: '__ico-wrap',
	ico: '__ico',
	spacer: '__spacer'
};


export const CLASSES_CHILD = {
	numberText: CLASSES_CORE.number + CLASSES_CHILD_POSTFIX.text,
	buttonText: CLASSES_CORE.button + CLASSES_CHILD_POSTFIX.text,
	buttonSpacer: CLASSES_CORE.button + CLASSES_CHILD_POSTFIX.spacer,
	buttonIco: CLASSES_CORE.button + CLASSES_CHILD_POSTFIX.ico,
	buttonIcoWrap: CLASSES_CORE.button + CLASSES_CHILD_POSTFIX.icoWrap,
};



export const MODIFIERS = {
	anim: '--anim',
	noOverlay: '--no-overlay',
	dummy: '--dummy',
	inHighlight: '--in-highlight',
	sec: '--sec',
	vert: '--vert',
	symbol: '--symbol',
	noText: '--no-text',
	onTooltip: '--on-tooltip',
	above: '--above',
	click: '--click',
	blockClick: '--block-click',
	hover: '--hover',
	fixed: '--fixed',
	zIdxFix: '--z-idx-fix',
	hidden: '--hidden',
	'2dig': '--2dig',
	customStyle: '--cs',
	whiteSpacingNormal: '--wsn',
	overLeft: '--over-left',
	overTop: '--over-top',
	overRight: '--over-right',
	overBottom: '--over-bottom',
	overflowHidden: '--hidden-overflow',
	full: '--full'
};

export const EVENTS = {
	started: 'started',
	onInit: 'onInit',
	onBeforeEnd: 'onBeforeEnd',
	ended: 'ended',
	beforeStepChange: 'beforeStepChange',
	afterStepChange: 'afterStepChange',
	onButtonHover: 'onButtonHover',
	onResize: 'onResize',
	onBeforeRedirect: 'onBeforeRedirect',
	onMobileMenuClose: 'onMobileMenuClose',
	onMobileMenuOpen: 'onMobileMenuOpen',
	onOverlayClick: 'onOverlayClick',
	onNoStepsInit: 'onNoStepsInit'
};

export const COMMANDS = {
	goToNext: 'goToNext',
	goToPrev: 'goToPrev',
	goToStep: 'goToStep',
	exit: 'exit',
	setAccent: 'setAccent',
	switchTheme: 'switchTheme',
	setCoreOptions: 'setCoreOptions',
	getCoreOptions: 'getCoreOptions',
	startTour: 'startTour'
};


export const RESIZE_REACTION = {
	none: 'none',
	soft: 'soft',
	hard: 'hard'
};

export const RESIZE_TYPE = {
	mobUrlBarVisibilityToggle: 'mobUrlBarVisibilityToggle',
	orientationToggle: 'orientationToggle',
	majorAreaChange: 'majorAreaChange',
	minorAreaChange: 'minorAreaChange',
};

export const REFRESH_REASONS = {
	unknown: 'unknown',
	regular: 'regular',
	followTarget: 'follow-target',
	resize: 'resize',
	builder: 'builder',
	stickyDetector: 'sticky-detector',
	restore: 'restore',
	finalize: 'finalize',
	switchTheme: 'switch-theme'
};

export const RES_PROCESS_ASYNC = {
	skip: 0,
	abort: -1,
	continue: 1,
	timeout: 2,
	deferred: 3,
	noData: -2,
	done: 4
};

export const SCROLL_SPEED = {
	constant: 1019.4,
	logCoef: -214.1,
	maxCoef: 3,
	maxParentScrollTimeMs: 1000
};

export const RESPONSIVE_MODE = {
	desktop: 'desktop',
	mobile: 'mobile',
	mobileMenu: 'mobileMenu'
};

export const CORE_ENGINES = {
	base: 'DpitCore',
	sticky: 'DpitCoreSticky',
	builder: 'DpitBuilderCore',
	builderSticky: 'DpitBuilderCoreSticky',
};

export const TIMING = {
	userScrolling: 700,
	finalizeExit: 500,
	resizeReaction: 500,
	defaultIterateAfterClick: 500,
	finalizeSoftRefresh: 250,
	defaultFlierScroll: 300,
	defaultTargetScroll: 500
};

export const SIZE_LIMITS_PX = {
	minTooltipContentHeightFixedPos: 20,
	minTooltipContentHeight: 120,
	minTooltipHeight: 150
};

